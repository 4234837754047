import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    components: {
      default: HomeView
    },
    children: [
      {
        path: '',
        name: 'Dashboard',
        components: {
          dashboard: () => import('../views/DashboardItem.vue')
        }
      },
      {
        path: '/transactions',
        name: 'Transactions',
        components: {
          dashboard: () => import('../views/TransactionsView.vue')
        }
      },
      {
        path: '/accounts',
        name: 'Accounts',
        components: {
          dashboard: () => import('../views/AccountsItem.vue')
        }
      },
      {
        path: '/expenses',
        name: 'Expenses',
        components: {
          dashboard: () => import('../views/ExpensesItem.vue')
        }
      },
      {
        path: '/billings',
        name: 'Billings',
        components: {
          dashboard: () => import('../views/BillingsView.vue')
        }
      },
      {
        path: '/profile',
        name: 'Profile',
        components: {
          dashboard: () => import('../views/ProfileItem.vue')
        }
      }
    ]
  },
  {
    path: '/sign-up',
    name: 'SignUp',
    component: () => import('../views/SignUp.vue')
  },
  {
    path: '/sign-in',
    name: 'SignIn',
    component: () => import('../views/SignIn.vue')
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
